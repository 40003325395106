<template>
    <div class="table-container-wrap">

        <div class="tt-header">
            <b-form-checkbox
                    id="show-all-checker-6" v-if="loggedUser.perm_task_comments == 1"
                    class="mr-1 show-all-checker mb-1"
                    value="1"
                    unchecked-value="0"
                    v-model="filterData.show_all"

            >
                <label for="show-all-checker-6">{{ $t('label_show_all') }}</label>
            </b-form-checkbox>
        </div>

        <b-table
                :ref="PREFIX+'_TABLE'"
                class="position-relative transited-table"
                :items="tableItems"
                responsive
                no-border-collapse
                :busy="isBusy"
                :fields="columnsDefs"
                primary-key="id"
                :sort-by.sync="tableData.sortBy"
                no-local-sorting
                :sort-desc.sync="tableData.sortDesc"
                show-empty
                :tbody-transition-props="{ name: 'flip-list'}"
                :empty-text="$t('label_no_table_data')"
                hover
                @row-clicked="processRowClick"
        >

            <template #head()="data">
                <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
            </template>
            <template #cell(contact)="data">
                sdsd
            </template>


        </b-table>

    </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import { TASK_PREFIX as PREFIX, AGREEMENT_PREFIX, resolveDeadlineText } from './../../agreement/moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,

            vSelect,

        },
        props:['loggedUser'],
        data() {
            return {

                PREFIX,
                AGREEMENT_PREFIX,

                selectedColumns:[],

                columnsDefs: [

                    {
                        label: 'label_task',
                        key: 'subject',
                        sortable: false,
                    },
                    {
                        label: 'label_content',
                        key: 'text',
                        sortable: false,
                    },
                    {
                        label: 'label_date',
                        key: 'created_at',
                        sortable: false,
                        formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }
                    },
                    {
                        label: 'label_user',
                        key: 'user_name',
                        sortable: false,

                    },

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [8, 10, 25, 50, 100],
                },
                filterData: {
                    sort_by_source: 'all',
                    show_all: 0,
                },


            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.refreshDataTable();
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/'+this.AGREEMENT_PREFIX+'_'+this.PREFIX+'_comments', {
                    params: {
                        // [this.PREFIX+'_id']:this.task.id,
                        // agreement_id: this.module_id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                        show_all: this.filterData.show_all,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            processRowClick(item, index, e){
                if(this.$can('view', 'agreement.tab_tasks') || this.$can('view', 'deal.tab_tasks'))
                    this.$router.push({name:'agreement_task_details', params: {id:item.agrm_deal_task_id}});
            }
        },

    }
</script>