<template>

    <div id="dashboard-page">

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item active>
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/> {{$t('label_dashboard')}}
                </b-breadcrumb-item>
            </div>
        </div>
        <b-card no-body v-if="password_change_warning">
        <b-alert class="m-2" variant="warning" show>
            <div class="" v-html="password_change_warning"></div>
        </b-alert>
        </b-card>

        <b-row v-if="$can('view', 'workingtime.tab_details')" class="mt-2 mb-2">
            <b-col cols="12">
                <b-card-actions
                        :title="$t('label_my_timesheet')"
                        action-collapse
                >
                    <time-sheet :task-types="taskTypes"></time-sheet>

                </b-card-actions>
            </b-col>
        </b-row>
        <b-row >
            <b-col v-if="$can('view', 'mail.tab_details')" cols="12" >
                <b-card-actions
                        title=""
                        action-collapse

                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="MailIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_undecided_mail') }}</span>
                        </div>
                    </template>
                    <undecided-mail-table :logged-user="loggedUser"></undecided-mail-table>
                </b-card-actions>
            </b-col>

            <b-col v-if="$can('view', 'mail.tab_details')" cols="12" >
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="MailIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_incoming_mail') }}</span>
                        </div>
                    </template>
                    <incoming-mail-table :logged-user="loggedUser"></incoming-mail-table>
                </b-card-actions>
            </b-col>
            <b-col v-if="$can('view', 'mail.tab_details')" cols="12" >
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="MailIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_outcoming_mail') }}</span>
                        </div>
                    </template>
                    <outcoming-mail-table :logged-user="loggedUser"></outcoming-mail-table>
                </b-card-actions>
            </b-col>
            <b-col cols="12" md="12" v-if="$can('view', 'workingtime.tab_details')">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="ClockIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_working_time') }}</span>
                        </div>
                    </template>
                    <working-time-table :logged-user="loggedUser"></working-time-table>
                </b-card-actions>
            </b-col>
            <b-col cols="12" md="6" v-if="$can('view', 'contact.tab_notes')">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="FileIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_contacts_notes') }}</span>
                        </div>
                    </template>
                    <contact-notes-table :logged-user="loggedUser"></contact-notes-table>
                </b-card-actions>
            </b-col>

            <b-col cols="12" md="6" v-if="$can('view', 'agreement.tab_tasks') || $can('view', 'deal.tab_tasks')">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="MessageCircleIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_tasks_comments') }}</span>
                        </div>
                    </template>
                    <task-comments-table :logged-user="loggedUser"></task-comments-table>
                </b-card-actions>
            </b-col>

            <b-col cols="12" v-if="$can('view', 'contact.tab_meetings')">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="CheckSquareIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_meetings_for_today') }}</span>
                        </div>
                    </template>
                    <current-meetings-table :logged-user="loggedUser"></current-meetings-table>
                </b-card-actions>
            </b-col>

            <b-col cols="12" v-if="$can('view', 'agreement.tab_tasks') || $can('view', 'deal.tab_tasks')">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="UsersIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_tasks') }}</span>
                        </div>
                    </template>
                    <current-tasks-table :logged-user="loggedUser" :task-sub-types="taskTypes"></current-tasks-table>
                </b-card-actions>
            </b-col>

            <b-col cols="12" v-if="$can('view', 'deal.tab_details')">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="NavigationIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_deals_activity') }}</span>
                        </div>
                    </template>
                    <last-activity-table :logged-user="loggedUser"></last-activity-table>
                </b-card-actions>
            </b-col>
        </b-row>

        <b-row v-if="$can('view', 'deal.tab_details')">
            <b-col cols="12" md="6">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="EyeIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_last_seen_deals') }}</span>
                        </div>
                    </template>
                    <last-seen-deals-table :logged-user="loggedUser"></last-seen-deals-table>
                </b-card-actions>
            </b-col>
            <b-col cols="12" md="6">
                <b-card-actions
                        title=""
                        action-collapse
                        class="table-card"
                >
                    <template v-slot:title>
                        <div class="d-flex align-items-center">
                            <feather-icon icon="EyeOffIcon" class="mr-1"></feather-icon>
                            <span class="">{{ $t('label_deals_without_activity') }}</span>
                        </div>
                    </template>
                    <without-activity-deals-table :logged-user="loggedUser"></without-activity-deals-table>
                </b-card-actions>
            </b-col>
        </b-row>



    </div>
</template>

<script>
    import undecidedMailTable from './includes/undecidedMailTable'
    import incomingMailTable from './includes/incomingMailTable'
    import outcomingMailTable from './includes/outcomingMailTable'
    import contactNotesTable from './includes/contactNotesTable'
    import workingTimeTable from './includes/workingTimeTable'
    import taskCommentsTable from './includes/taskCommentsTable'
    import currentTasksTable from './includes/currentTasksTable'
    import currentMeetingsTable from './includes/currentMeetingsTable'
    import lastActivityTable from './includes/lastActivityTable'
    import lastSeenDealsTable from './includes/lastSeenDealsTable'
    import withoutActivityDealsTable from './includes/withoutActivityDealsTable'
    import timeSheet from './includes/timeSheet'

    import BCardActions from './../../components/BCardActions.vue'
    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip, BAlert, BCardText,
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import VueApexCharts from 'vue-apexcharts'

    export default {
        components: {
            BCard,

            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            BAlert,
            BCardText,
            BCardActions,
            vSelect,
            undecidedMailTable,
            incomingMailTable,
            outcomingMailTable,
            contactNotesTable,
            workingTimeTable,
            taskCommentsTable,
            currentTasksTable,
            currentMeetingsTable,
            lastActivityTable,
            lastSeenDealsTable,
            withoutActivityDealsTable,
            timeSheet,

        },

        data() {
            return {
                taskTypes: [],
                // cards: [],
                password_change_warning: '',
                loggedUser: false

            }
        },


        created() {
            // console.log(1,new Date()); // client time
            // console.log(2,this.$moment.now()); //timestamp client time
            // console.log(3,this.$moment.format('YYYY-MM-DD HH:mm:ss')); // client time
            // console.log(4,this.$moment.utc(this.$moment('2022-02-07 13:12:18','YYYY-MM-DD HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss'));//UTC
            // console.log(5,this.$moment.unix(1644228560).format('YYYY-MM-DD HH:mm:ss')); // without changes
            // console.log(6,this.$moment('2022-02-07 13:12:18','YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')); // without changes
            this.loggedUser = this.authUser();

            this.async('get', '/dashboard', {}, function (resp) {
                // this.cards = resp.data.stats;
                this.password_change_warning = resp.data.password_change_warning;
            });

            this.async('get', '/select_options', {params:{options:['agrm_task_types', 'registry_agrm_task_types']}}, function(res){
                this.taskTypes = res.data.agrm_task_types.concat(res.data.registry_agrm_task_types);
                // this.task_intervals = res.data.task_intervals;
            });
        },



    }
</script>
