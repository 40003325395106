<template>
    <div>

        <div class="d-flex flex-wrap align-items-center">
            <b-form-radio v-for="item in [{label:'label_in_process', alias:'real_time'},{label:'label_unsettled', alias:'declarative_time'}]"
                          class="mr-1 mb-1"
                          v-model="displayed_table"
                          button
                          size="sm"
                          button-variant="outline-primary"
                          :value="item.alias"
            >
                <span style="white-space: nowrap;">{{$t(item.label)}}</span>
            </b-form-radio>
            <b-form-checkbox v-if="loggedUser.perm_working_time == 1"
                    id="show-all-checker-5"
                    class="mr-1 mb-1"
                    value="1"
                    unchecked-value="0"
                    v-model="filterData.show_all"

            >
                <label for="show-all-checker-5">{{ $t('label_show_all') }}</label>
            </b-form-checkbox>
        </div>



        <div class="table-container-wrap">
            <b-table

                    :ref="PREFIX+'_TABLE'"
                    class="position-relative transited-table styled-footer"
                    :items="tableItems"
                    responsive
                    no-border-collapse
                    :busy="isBusy"
                    :fields="columnsDefs"
                    primary-key="id"
                    :sort-by.sync="tableData.sortBy"
                    no-local-sorting
                    :sort-desc.sync="tableData.sortDesc"
                    show-empty
                    :tbody-transition-props="{ name: 'flip-list'}"
                    :empty-text="$t('label_no_table_data')"
                    hover
                    @row-clicked="processRowClick"

            >

                <template #head()="data">
                    <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                </template>


                <template #cell(declarative_time)="data">
                    <span :class="'badge badge-light-'+ (data.item.declarative_time==1? 'warning' : 'secondary')">{{data.item.declarative_time==1? $t('label_declarative_time') : $t('label_real_time')}}</span>
                </template>

                <template #cell(source)="data">
                    {{data.item.document_source == 'default'? data.item.user_name : $t('label_scanning_device')}}
                </template>
                <template #cell(user_name)="data">
                    {{data.item.user? data.item.user.name : ''}}
                </template>
                <template #cell(report_file)="data">

                    <a target="_blank" :href="$domain + data.item.report_dir + data.item.report_file">
                        <feather-icon icon="FileIcon"></feather-icon>
                    </a>

                </template>
                <template #cell(agreement_deal_number)="data">

                    <template v-if="data.item.agreement">
                        <router-link v-if="data.item.agreement.is_deal && data.item.agreement.deal && $can('view', 'deal.tab_details')"
                                     :to="{name: 'deal-details', params:{id:data.item.agreement.deal.id}}">
                            {{data.item.agreement.deal_number}}
                        </router-link>
                        <router-link v-else-if="data.item.agreement &&  $can('view', 'agreement.tab_details')"
                                     :to="{name: 'agreement-details', params:{id:data.item.agreement.id}}">
                            {{data.item.agreement.deal_number}}
                        </router-link>
                        <template v-else>
                            {{data.item.agreement.deal_number}}
                        </template>
                    </template>
                    <span v-else>--</span>

                </template>

            </b-table>
        </div>

    </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip, BFormRadio,
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    import {
        DEAL_PREFIX as MODULE_PREFIX,
        WORKING_TIME_PREFIX as PREFIX,
        // workTimeTables as tableTypes,
        // workingTypes
    } from './../../deal/moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,
            BFormRadio,
            vSelect,

        },
        props:['loggedUser'],
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                columnsDefs: [
                    {label: 'label_deal_number', key: 'agreement_deal_number'},
                    {label: 'label_type_of_activity', key: 'declarative_time'},
                    {label: 'label_name', key: 'name_activity', sortable: false},
                    {label: 'label_user', key: 'user_name'},

                    {label: 'label_time_of_the_activity', key: 'worked_time_seconds', formatter(v) {
                            return formatSeconds(v)
                        }, sortable: false
                    },

                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [8, 10, 25, 50, 100],
                },
                filterData: {
                    sort_by_source: 'all',
                    show_all: 0,
                },
                // sourceOptions:[],
                displayed_table: 'declarative_time',
            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            },
            displayed_table: function (cur_table) {
                this.refreshDataTable();
                // this.displayTable(cur_table);
            },
        },

        created() {

            this.refreshDataTable();
        },

        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                let url = '';

                let params = {
                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,
                    show_all: this.filterData.show_all,
                };

                if (this.displayed_table == 'declarative_time' || this.displayed_table == 'real_time' || this.show_pdf_detailing) {
                    url = '/' +  this.PREFIX ;
                }

                params.extra_search = this.displayed_table;

                this.async('get', url, {
                    params: params
                }, function (resp) {

                    // if (this.displayed_table == 'declarative_time' || this.displayed_table == 'real_time') {
                        // this.summaryData = resp.data.items[0] ? resp.data.items[0].summary : {};
                    // }

                    // if (this.needUpdateColumns) {
                    //     this.needUpdateColumns = false;
                    //
                    //     if (this.displayed_table == 'declarative_time' || this.displayed_table == 'real_time' || this.show_pdf_detailing) {
                    //         this.columnsDefs = this.columnsDefs_declarative_time;
                    //     } else if (this.displayed_table == 'this_month' || this.displayed_table == 'all_tasks') {
                    //         this.columnsDefs = this.columnsDefs_this_month.filter((item) => item.key != 'actions');
                    //     } else if(this.displayed_table == 'alerts') {
                    //         this.columnsDefs = this.columnsDefs_alerts;
                    //     }
                    //
                    // }
                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;

                    this.isBusy = false;
                });
            },
            processRowClick(item, index, e){
                if(this.$can('view', 'deal.tab_working_time_report'))
                this.$router.push({name:'deal_tabs', params: {id:item.deal_id, alias: 'workingtime'}});
            }
        },

    }
</script>