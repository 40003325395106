<template>
    <div class="table-container-wrap">

        <b-table
                :ref="PREFIX+'_TABLE'"
                class="position-relative transited-table"
                :items="tableItems"
                responsive
                no-border-collapse
                :busy="isBusy"
                :fields="columnsDefs"
                primary-key="id"
                :sort-by.sync="tableData.sortBy"
                no-local-sorting
                :sort-desc.sync="tableData.sortDesc"
                show-empty
                :tbody-transition-props="{ name: 'flip-list'}"
                :empty-text="$t('label_no_table_data')"
                hover
                @row-clicked="processRowClick"
        >

            <template #head()="data">
                <span class="text-black-50">{{ $t(data.label).ucFirst() }}</span>
            </template>
            <template #head(deal_or_agreement)="data">
                {{$t('label_agreement') + '/' + $t('label_deal')}}
            </template>

            <template #cell(agreement_deal_number)="data">

                <template v-if="data.item.agreement">
                    <router-link v-if="data.item.agreement.is_deal && data.item.agreement.deal && $can('view', 'deal.tab_details')"
                                 :to="{name: 'deal-details', params:{id:data.item.agreement.deal.id}}">
                        {{data.item.agreement.deal_number}}
                    </router-link>
                    <router-link v-else-if="data.item.agreement &&  $can('view', 'agreement.tab_details')"
                                 :to="{name: 'agreement-details', params:{id:data.item.agreement.id}}">
                        {{data.item.agreement.deal_number}}
                    </router-link>
                    <template v-else>
                        {{data.item.agreement.deal_number}}
                    </template>
                </template>
                <span v-else>--</span>

            </template>


        </b-table>


    </div>
</template>

<script>

    import {
        BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BCardBody,
            BFormCheckbox,

            BCardHeader,
            BTooltip,

            vSelect,

        },
        // props:['tableType'],
        data() {
            return {
                // MODULE_PREFIX,
                PREFIX: 'without_activity',

                // checkedIds: [],

                selectedColumns:[],

                columnsDefs: [

                    {
                        label: 'label_deal',
                        key: 'agreement_deal_number',
                        sortable: false,
                    },

                    {
                        label: 'label_last_date_of_activity',
                        key: 'date',
                        sortable: false,
                        formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss');
                        }
                    },


                ],
                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 5,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    // perPageOptions: [8, 10, 25, 50, 100],
                },


            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            this.refreshDataTable();
        },


        methods: {

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/deal/without_activity', {
                    params: {
                        // contact_id: this.moduleItem.id,
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                    }
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            processRowClick(item, index, e){

                if(item.deal_id && this.$can('view', 'deal.tab_details')){
                    this.$router.push({name:'deal-details', params: {id:item.deal_id}});
                } else if(this.$can('view', 'agreement.tab_details')){
                    this.$router.push({name:'agreement-details', params: {id:item.agreement_id}});
                }

            }
        },

    }
</script>